import React from 'react';
import CardSevice from '../Card/CardSevice';

const Service = (props) => {
    return (
        <section style={{ margin: 0, padding: 0 }}>
            <div className='container'>
                <div className='text-center mb-5'>
                    <h3 className='bold font__size--58 text__50-1024 text__50-sm text__50-xs text-uppercase color__orange'> Our Service</h3>
                    <p className='roboto semi-bold font__size--16 text__16-1024'>
                        Global HR Inc. provides a range of exceptional transportation solutions tailored to the needs of our clients, ensuring
                        efficiency, reliability, and flexibility. Our services include:
                    </p>
                </div>

                <div className='row mb-5'>
                    {props.service.map((obj) => {
                        return (
                            <div className='col-lg-4 mb-4'>
                                <CardSevice data={obj} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default Service;
