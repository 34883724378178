import React from 'react';

const About1 = () => {
    return (
        <section style={{ margin: 0, padding: 0 }}>
            <div className='container'>
                <div className='row '>
                    <div className='col-md-7 col-lg-6 my-auto pl-lg-5'>
                        <h3 className='bold font__size--58 text__50-1024 text__50-sm text__50-xs text-uppercase color__orange'>Our Values</h3>

                        <p className='roboto normal font__size--16 text__16-1024 color__gray-1'>
                            <span style={{ fontWeight: 800 }}>Integrity:</span> We conduct business with honesty, transparency, and ethical practices,
                            fostering trust and accountability in all our interactions.
                        </p>
                        <p className='roboto normal font__size--16 text__16-1024 color__gray-1'>
                            <span style={{ fontWeight: 800 }}>Professionalism:</span> We uphold the highest standards of professionalism, ensuring the
                            safety, security, and satisfaction of our clients at all times.
                        </p>
                        <p className='roboto normal font__size--16 text__16-1024 color__gray-1'>
                            <span style={{ fontWeight: 800 }}>Excellence:</span>We strive for excellence in everything we do, continuously seeking to
                            improve and innovate to exceed the expectations of our clients and stakeholders.
                        </p>
                        <p className='roboto normal font__size--16 text__16-1024 color__gray-1'>
                            <span style={{ fontWeight: 800 }}>Collaboration:</span> We foster a culture of collaboration and teamwork, leveraging our
                            employees' diverse talents and expertise to achieve collective success.
                        </p>
                        <p className='roboto normal font__size--16 text__16-1024 color__gray-1'>
                            <span style={{ fontWeight: 800 }}>Customer-Centricity:</span> We are dedicated to understanding and fulfilling the needs
                            of our clients, delivering personalized solutions and exceptional service to ensure their satisfaction.
                        </p>
                        <p className='roboto normal font__size--16 text__16-1024 color__gray-1'>
                            <span style={{ fontWeight: 800 }}>Safety:</span>Safety: We prioritize the safety and well-being of our employees, clients,
                            and the communities we serve, adhering to the highest standards of safety and compliance.
                        </p>
                    </div>
                    <div className='col-md-5 mb-4'>
                        <img src='/values.jpg' className='w-100' alt='' style={{ objectFit: 'cover', minHeight: '100%' }} />
                    </div>
                </div>
            </div>
        </section>
    );
};
export default About1;
