import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Homepage from './homepage/index';
import About from './homepage/About';
import Service from './homepage/Service';
import Project from './homepage/Project';
import News from './homepage/News';
import DetailService from './homepage/DetailService';
import DetailProject from './homepage/DetailProject';
import DetailNews from './homepage/DetailNews';
import Contact from './homepage/Contact';
import Terms from './homepage/Terms';
import Privacy from './homepage/Privacy';

const Index = (props) => {
    return (
        <Fragment>
            <Router forceRefresh>
                <Switch>
                    <Route exact path='/'>
                        <Homepage />
                    </Route>
                    <Route exact path='/about'>
                        <About />
                    </Route>
                    <Route exact path='/service'>
                        <Service />
                    </Route>
                    <Route exact path='/project'>
                        <Project />
                    </Route>
                    <Route exact path='/news'>
                        <News />
                    </Route>
                    <Route exact path='/contact'>
                        <Contact />
                    </Route>
                    <Route exact path='/service/detail'>
                        <DetailService />
                    </Route>
                    <Route exact path='/project/detail'>
                        <DetailProject />
                    </Route>
                    <Route exact path='/news/detail'>
                        <DetailNews />
                    </Route>
                    <Route exact path='/terms'>
                        <Terms />
                    </Route>
                    <Route exact path='/privacy'>
                        <Privacy />
                    </Route>
                </Switch>
            </Router>
        </Fragment>
    );
};

export default Index;
