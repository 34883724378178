import React, { Fragment } from 'react';
import { useState } from 'react';

const Navbar = (props) => {
    const [toogleMenu, setToogleMenu] = useState(false);

    return (
        <Fragment>
            <div className={'wrapper__side-nav-mobile d-flex d-lg-none ' + (toogleMenu ? 'active' : '')}>
                <div className='d-flex flex-wrap w-100'>
                    <div className='w-100'>
                        <div className='d-flex align-items-center justify-content-between mb-4'>
                            <div style={{ width: '10%' }}>
                                <img src='/icon.svg' alt='logo' />
                            </div>
                            <img src='./../images/Close.png' onClick={() => setToogleMenu(!toogleMenu)} className='pointer' alt='' />
                        </div>
                        <div className='menu'>
                            <a href='#home' exact className='medium font__size--14 text__14-1024 color__white'>
                                Home
                            </a>
                            <hr />
                            <div className='position-relative'>
                                <a href='#about' exact className='medium font__size--14 text__14-1024 color__white'>
                                    About
                                </a>
                            </div>
                            <hr />
                            <a href='#service' className='medium font__size--14 text__14-1024 color__white'>
                                Service
                            </a>
                            <hr />
                            <a href='#process' className='medium font__size--14 text__14-1024 color__white'>
                                Process
                            </a>
                            <hr />
                            <a href='#why' className='medium font__size--14 text__14-1024 color__white'>
                                Why choose us?
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg__wrap-menu d-lg-none' onClick={() => setToogleMenu(!toogleMenu)}></div>
            <nav
                className='navbar navbar-expand-lg bg__black-2 wrapper__navbar position-relative z-2'
                data-aos='fade-down'
                data-aos-duration='1000'
                data-aos-delay='300'
            >
                <div className='container position-relative'>
                    <a href='/' exact style={{ minWidth: '170px' }}>
                        <img src='/logo-white.svg' alt='logo' />
                    </a>

                    <div className='collapse navbar-collapse wrapper__navbar-menu ml-lg-5' id='navbarSupportedContent'>
                        <ul className='navbar-nav menu__center'>
                            <li className='nav-item'>
                                <a
                                    href='#home'
                                    exact
                                    class='nav-link semi-bold font__size--14 text__14-1024 color__white opacity__9 text-uppercase hover'
                                >
                                    Home
                                </a>
                            </li>

                            <li className='nav-item'>
                                <a
                                    href='#about'
                                    exact
                                    class='nav-link semi-bold font__size--14 text__14-1024 color__white opacity__9 text-uppercase hover'
                                >
                                    about
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a
                                    href='#service'
                                    exact
                                    class='nav-link semi-bold font__size--14 text__14-1024 color__white opacity__9 text-uppercase hover'
                                >
                                    service
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a
                                    href='#process'
                                    class='nav-link semi-bold font__size--14 text__14-1024 color__white opacity__9 text-uppercase hover'
                                >
                                    Process
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a href='#why' class='nav-link semi-bold font__size--14 text__14-1024 color__white opacity__9 text-uppercase hover'>
                                    Why Choose Us?
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div onClick={() => setToogleMenu(!toogleMenu)} class='nav__button d-lg-none position-relative ml-auto flex-shrink-0'>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </nav>
        </Fragment>
    );
};

export default Navbar;
