import React, { useState } from 'react';

const CardSevice = (props) => {
    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };
    return (
        <div className='wrapper__card-service color__black d-inline-block w-100'>
            <img src={props.data.img} className='img' alt='' />
            <div className='desc'>
                <div className='h5 medium font__size--16 text__20-1024 text-uppercase'>{props.data.title}</div>
                <p className='normal font__size--16 text__16-1024 roboto color__gray-2'>
                    {showFullDescription ? props.data.desc : `${props.data.desc.substring(0, 80)}...`}
                </p>
                <div className='d-inline-block'>
                    <a href='#!' className='color__orange text-uppercase' onClick={toggleDescription}>
                        {showFullDescription ? 'read less' : 'read more'}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default CardSevice;
