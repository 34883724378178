import React from 'react';

const About = () => {
    return (
        <section>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-md-5 mb-4 '>
                        <img src='/about.jpg' className='w-100 ' alt='' style={{ minHeight: '100%', objectFit: 'cover' }} />
                    </div>
                    <div className='col-md-7 col-lg-6 my-auto pl-lg-5'>
                        <h3 className='bold font__size--58 text__50-1024 text__50-sm text__50-xs color__orange text-uppercase'>about us</h3>
                        <p className='roboto normal font__size--16 text__16-1024 color__gray-1'>
                            At Global HR Inc. we are dedicated to redefining excellence in the auto transport industry. Our mission is to provide
                            comprehensive and reliable solutions, ensuring the highest professionalism, safety, and customer satisfaction standards.
                        </p>
                        <p className='roboto normal font__size--16 text__16-1024 color__gray-1'>
                            With a vision to be the leading global provider of transportation solutions, we strive to achieve this by delivering
                            innovative, efficient, and cost-effective services tailored to our clients' unique needs.
                        </p>
                        <div
                            className='order-12 order-md-1 col-md-12 my-auto pr-lg-5'
                            style={{
                                padding: 0,
                            }}
                        >
                            <div className='d-flex align-items-start'>
                                <div>
                                    <h4 className='medium font__size--20 text__20-1024 text-uppercase'>Our vision</h4>
                                    <p className='normal font__size--16 text__16-1024 color__gray-1 roboto'>
                                        To provide comprehensive and reliable transportation services supported by highly skilled professionals,
                                        cutting-edge technology, and a commitment to continuous improvement.
                                    </p>
                                </div>
                            </div>
                            <div className='d-flex align-items-start'>
                                <div>
                                    <h4 className='medium font__size--20 text__20-1024 text-uppercase'>Our mission</h4>
                                    <p className='normal font__size--16 text__16-1024 color__gray-1 roboto'>
                                        To provide comprehensive and reliable transportation services supported by highly skilled professionals,
                                        cutting-edge technology, and a commitment to continuous improvement.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default About;
