import React, { Fragment } from 'react';
import Footer from '../../component/Other/Footer';
import Navbar from '../../component/Other/Navbar';
import Contact from '../../component/Section/Contact';
import ServiceSec from '../../component/Section/Service';
import Head from '../../component/Section/Head';
import Number from '../../component/Section/Number';
import Step from '../../component/Section/Step';

const Service = () => {
    const service = [
        {
            img: './../images/Image (4).png',
            title: 'Professional Truck Drivers',
            desc: 'Highly skilled drivers trained in Mongolia and certified by the Federal Motor Carrier Safety Administration (FMCSA), ensuring the safe and efficient transport. Our drivers can be employed for up to three years, providing stability and continuity for your operations.',
        },
        {
            img: './../images/Image (5).png',
            title: 'Professional Drivers for Other Trucks',
            desc: 'Besides car hauling drivers, we also provide specialized drivers for other types of trucks, including tanker trucks, box trucks, and more. Our drivers undergo specialized training to handle various types of trucks and freight, ensuring safe and efficient transportation of goods.',
        },
        {
            img: './../images/Image (6).png',
            title: 'Comprehensive Training Programs',
            desc: 'Our training program goes beyond traditional methods, including English language training, mindset safety management training, and complete medical examination. This ensures that our drivers are equipped not only with technical skills but also with effective communication, stress management, and health maintenance skills, contributing to their overall well-being and professionalism on the road.',
        },
        {
            img: './../images/ds (1).png',
            title: 'Dispatch Operators',
            desc: 'Our dispatch operators, based in Mongolia, work remotely to provide round-the-clock support, enabling seamless communication and coordination for our clients transportation needs. With availability 24/7 weekly, our dispatch operators ensure prompt response and assistance at any hour, enhancing operational efficiency and customer satisfaction.',
        },
        {
            img: './../images/ds (3).png',
            title: 'Skilled Mechanics for Car Hauling and Other Trucks',
            desc: 'Long-term employment options for mechanics specialized in repairing and examining car hauling and other trucks, ensuring the reliability and safety of your fleet. Our mechanics can be employed for up to three years, providing consistent maintenance and support for your vehicles.',
        },
    ];
    return (
        <Fragment>
            <div className='overflow-hidden'>
                <div className='position-relative'>
                    <img src='./../images/Bg.png' className='images__wrap-head' alt='' />
                    <div className='wrapper__bg-head'></div>
                    <Navbar />
                    <Head default='home' current='Our Service' title={'Our Service'} />
                </div>

                <ServiceSec service={service} type='service' />

                <Number />

                <Step />

                <Contact />

                <Footer />
            </div>
        </Fragment>
    );
};

export default Service;
