import React from 'react';

const Step1 = () => {
    return (
        <section>
            <div className='container'>
                <div className='row' style={{ flexDirection: 'row-reverse', display: 'flex' }}>
                    <div className='order-12 order-md-1 col-md-6 my-auto pr-lg-5'>
                        <h3 className='bold font__size--58 text__50-1024 text__50-sm text__50-xs text-uppercase color__orange'>Our Process</h3>
                        <div className='d-flex align-items-start my-5'>
                            <img src='./../images/consulting.png' className='object-fit-content' alt='' />
                            <div className='ml-3'>
                                <h4 className='medium font__size--20 text__20-1024 text-uppercase'>Consultation</h4>
                                <p className='normal font__size--16 text__16-1024 color__gray-1 roboto'>
                                    We collaborate closely with clients to understand their auto transport requirements and develop tailored solutions
                                    to meet their needs.
                                </p>
                            </div>
                        </div>
                        <div className='d-flex align-items-start my-4'>
                            <img src='./../images/checking.png' className='object-fit-content' alt='' />
                            <div className='ml-3'>
                                <h4 className='medium font__size--20 text__20-1024 text-uppercase'>Recruitment and Training</h4>
                                <p className='normal font__size--16 text__16-1024 color__gray-1 roboto'>
                                    Our team provides continuous support and supervision to drivers and mechanics, ensuring they have the necessary
                                    resources and assistance to excel in their roles.
                                </p>
                            </div>
                        </div>
                        <div className='d-flex align-items-start'>
                            <img src='./../images/planning.png' className='object-fit-content' alt='' />
                            <div className='ml-3'>
                                <h4 className='medium font__size--20 text__20-1024 text-uppercase'>Ongoing Support</h4>
                                <p className='normal font__size--16 text__16-1024 color__gray-1 roboto'>
                                    To provide comprehensive and reliable transportation services supported by highly skilled professionals,
                                    cutting-edge technology, and a commitment to continuous improvement.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='order-md-12 order-1 mb-4 col-md-6 col-lg-5 text-right'>
                        <img src='/process.jpg' className='w-100' alt='' style={{ objectFit: 'cover', minHeight: '100%' }} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Step1;
