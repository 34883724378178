import React from 'react';

const Footer = (props) => {
    return (
        <div className='container' style={{ paddingTop: 40, paddingBottom: 0 }}>
            <div className='row' style={{ justifyContent: 'space-between' }}>
                <div className='col-md-3 mb-4 mb-md-0'>
                    <div style={{ width: '80%', marginBottom: 10 }}>
                        <img src='/logo.svg' alt='logo' />
                    </div>
                    <p className='normal font__size--16 text__16-1024 roboto color__gray-1'>
                        Ready to experience the difference with Global HR Inc.? Contact us today to discuss your auto transport needs and let us help
                        you achieve your transportation goals with confidence and peace of mind.
                    </p>
                </div>

                <div>
                    <h5 className='semi-bold font__size--14 text__14-1024 text-uppercase mb-3'>CONTACT US</h5>
                    <div className='d-flex align-items-center mb-3'>
                        <img src='./../images/Message.png' alt='' />
                        <span className='semi-bold font__size--14 text__14-1024 text-uppercase ml-2'>info@global-hr.us</span>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                        <img src='./../images/Frame.png' alt='' />
                        <span>
                            <a
                                style={{ textDecoration: 'none', color: '#212529' }}
                                href='http://www.global-hr.us'
                                className='semi-bold font__size--14 text__14-1024 text-uppercase ml-2'
                            >
                                www.global-hr.us
                            </a>
                        </span>
                    </div>
                    <div className='d-flex align-items-center'>
                        <img src='./../images/Call.png' alt='' />
                        <span className='semi-bold font__size--14 text__14-1024 text-uppercase ml-2'>+1-202-802-1899</span>
                    </div>
                </div>
                <div
                    className='col-6 col-md-3  mb-md-0'
                    style={{ justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                    <ul className='wrapper__list-footer'>
                        <h5 className='semi-bold font__size--14 text__14-1024 text-uppercase '>links</h5>
                        <li>
                            <a href='#home' className='semi-bold font__size--14 text__14-1024 color__black text-uppercase opacity__7 hover'>
                                home
                            </a>
                        </li>
                        <li>
                            <a href='#about' className='semi-bold font__size--14 text__14-1024 color__black text-uppercase opacity__7 hover'>
                                about
                            </a>
                        </li>
                        <li>
                            <a href='#service' className='semi-bold font__size--14 text__14-1024 color__black text-uppercase opacity__7 hover'>
                                service
                            </a>
                        </li>
                        <li>
                            <a href='#home' className='semi-bold font__size--14 text__14-1024 color__black text-uppercase opacity__7 hover'>
                                Our process
                            </a>
                        </li>
                        <li>
                            <a href='#home' className='semi-bold font__size--14 text__14-1024 color__black text-uppercase opacity__7 hover'>
                                Why choose us?
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='col-6 col-md-3 mb-md-0' style={{ padding: 0 }}>
                    <h5 className='semi-bold font__size--14 text__14-1024 text-uppercase mb-3'>follow our social account</h5>

                    <div className='d-flex align-items-center'>
                        <a href='https://facebook.com/globalhr.inc'>
                            <img src='./../images/FB.png' alt='' />
                        </a>
                        <a href='https://www.instagram.com/globalhr.inc/' className='mx-3'>
                            <img src='./../images/IG.png' alt='' />
                        </a>
                    </div>
                </div>

                {/* <div className='col-md-4'>
                        <h5 className='semi-bold font__size--14 text__14-1024 text-uppercase mb-3'>subscribe</h5>

                        <input
                            type='text'
                            className='form-control wrapper__field roboto normal font__size--16 text__16-1024 mb-3'
                            placeholder='Enter your email'
                            name=''
                            id=''
                        />
                        <button className='semi-bold font__size--14 text__14-1024 color__white text-uppercase btn btn__orange shadow rounded-0'>
                            send
                        </button>
                    </div> */}
            </div>
            <div className='text-center normal roboto font__size--16 text__16-1024 color__gray-1 mt-5'>
                Copyright GlobalHR. 2024 All Right Reserved
            </div>
        </div>
    );
};

export default Footer;
