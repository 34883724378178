import React from 'react';

const Step = () => {
    return (
        <section>
            <div className='container'>
                <div className='row'>
                    <div className='order-12 order-md-1 col-md-6 my-auto pr-lg-5'>
                        <div className='d-flex align-items-start my-5'>
                            <img src='./../images/checking.png' className='object-fit-content' alt='' />
                            <div className='ml-3'>
                                <h4 className='medium font__size--20 text__20-1024 text-uppercase'>Our vision</h4>
                                <p className='normal font__size--16 text__16-1024 color__gray-1 roboto'>
                                    To provide comprehensive and reliable transportation services supported by highly skilled professionals,
                                    cutting-edge technology, and a commitment to continuous improvement.
                                </p>
                            </div>
                        </div>
                        <div className='d-flex align-items-start'>
                            <img src='./../images/planning.png' className='object-fit-content' alt='' />
                            <div className='ml-3'>
                                <h4 className='medium font__size--20 text__20-1024 text-uppercase'>Our mission</h4>
                                <p className='normal font__size--16 text__16-1024 color__gray-1 roboto'>
                                    To provide comprehensive and reliable transportation services supported by highly skilled professionals,
                                    cutting-edge technology, and a commitment to continuous improvement.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='order-md-12 order-1 mb-4 col-md-6 col-lg-5 my-md-auto text-right'>
                        <img src='./../images/sdsadas.png' className='w-100' alt='' />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Step;
