import React from 'react';

const Contact = () => {
    return (
        <section className='position-relative'>
            <img src='/why.jpg' className='images__wrap-contact' alt='' />
            <div className='wrapper__bg-orange-wrap'></div>

            <div className='container position-relative z-2'>
                <h3
                    className='bold font__size--58 text__50-1024 text__50-sm text__50-xs color__white text-uppercase mb-4 mr-5'
                    style={{ textAlign: 'center' }}
                >
                    Why choose us
                </h3>
                <div className='row'>
                    <div className='col-md-6 col-xl-5 '>
                        <div className='d-flex align-items-start'>
                            <img src='./../images/asass.svg' className='object-fit-content mt-2' alt='' />
                            <p className='ml-3 normal roboto font__size--16 text__20-1024' style={{ color: '#FFFFFF' }}>
                                <span style={{ fontWeight: 800 }}>Comprehensive Solutions:</span> From professional drivers to skilled mechanics, we
                                provide a comprehensive range of services to meet all your auto transport needs under one roof.
                            </p>
                        </div>
                        <div className='d-flex align-items-start'>
                            <img src='./../images/asass.svg' className='object-fit-content mt-2' alt='' />
                            <p className='ml-3 normal roboto font__size--16 text__20-1024' style={{ color: '#FFFFFF' }}>
                                <span style={{ fontWeight: 800 }}>International Expertise:</span> Our drivers trained in Mongolia bring a wealth of
                                international experience and expertise to the table, ensuring the highest level of professionalism, diversity, and
                                competency.
                            </p>
                        </div>
                        <div className='d-flex align-items-start'>
                            <img src='./../images/asass.svg' className='object-fit-content mt-2' alt='' />
                            <p className='ml-3 normal roboto font__size--16 text__20-1024' style={{ color: '#FFFFFF' }}>
                                <span style={{ fontWeight: 800 }}> Regulatory Compliance:</span> All our drivers hold legal licenses from FMCSA, and
                                our mechanics adhere to industry regulations and standards, ensuring compliance and safety.
                            </p>
                        </div>
                    </div>
                    <div className='col-md-6 col-xl-5 offset-xl-1'>
                        <ul className='list__detail-info'>
                            <li>
                                <div className='d-flex align-items-start'>
                                    <img src='./../images/asass.svg' className='object-fit-content mt-2' alt='' />
                                    <p className='ml-3 normal roboto font__size--16 text__20-1024' style={{ color: '#FFFFFF' }}>
                                        <span style={{ fontWeight: 800 }}> Visa Compliance:</span> We handle all visa compliance aspects for our
                                        drivers, ensuring seamless and hassle-free employment in the USA.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className='d-flex align-items-start'>
                                    <img src='./../images/asass.svg' className='object-fit-content mt-2' alt='' />
                                    <p className='ml-3 normal roboto font__size--16 text__20-1024' style={{ color: '#FFFFFF' }}>
                                        <span style={{ fontWeight: 800 }}>Budget-Effective Benefits:</span> While providing top-notch services, we
                                        also offer budget-effective solutions tailored to your financial constraints. Our cost-effective approach
                                        ensures you receive high-quality car transport services without breaking the bank.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className='d-flex align-items-start'>
                                    <img src='./../images/asass.svg' className='object-fit-content mt-2' alt='' />
                                    <p className='ml-3 normal roboto font__size--16 text__20-1024' style={{ color: '#FFFFFF' }}>
                                        <span style={{ fontWeight: 800 }}>Continuous Support:</span> With our 24/7 dispatch operator service, drivers
                                        and clients can access support and assistance around the clock, enhancing operational efficiency and peace of
                                        mind.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
