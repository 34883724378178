import React, { Fragment } from 'react';
import Footer from '../../component/Other/Footer';
import Navbar from '../../component/Other/Navbar';
import About from '../../component/Section/About';
import Service from '../../component/Section/Service';
import About1 from '../../component/Section/About1';
import Step1 from '../../component/Section/Step1';
import Contact from '../../component/Section/Contact';

const Index = (props) => {
    const service = [
        {
            img: '/1.jpg',
            title: 'Professional Truck Drivers',
            desc: 'Highly skilled drivers trained in Mongolia and certified by the Federal Motor Carrier Safety Administration (FMCSA), ensuring the safe and efficient transport. Our drivers can be employed for up to three years, providing stability and continuity for your operations.',
        },
        {
            img: '/2.jpg',
            title: 'Professional Drivers for Other Trucks',
            desc: 'Besides car hauling drivers, we also provide specialized drivers for other types of trucks, including tanker trucks, box trucks, and more. Our drivers undergo specialized training to handle various types of trucks and freight, ensuring safe and efficient transportation of goods.',
        },
        {
            img: '/3.jpg',
            title: 'Comprehensive Training Programs',
            desc: 'Our training program goes beyond traditional methods, including English language training, mindset safety management training, and complete medical examination. This ensures that our drivers are equipped not only with technical skills but also with effective communication, stress management, and health maintenance skills, contributing to their overall well-being and professionalism on the road.',
        },
        {
            img: '/4.jpg',
            title: 'Dispatch Operators',
            desc: 'Our dispatch operators, based in Mongolia, work remotely to provide round-the-clock support, enabling seamless communication and coordination for our clients transportation needs. With availability 24/7 weekly, our dispatch operators ensure prompt response and assistance at any hour, enhancing operational efficiency and customer satisfaction.',
        },
        {
            img: '/5.jpg',
            title: 'Skilled Mechanics for Car Hauling and Trucks',
            desc: 'Long-term employment options for mechanics specialized in repairing and examining car hauling and other trucks, ensuring the reliability and safety of your fleet. Our mechanics can be employed for up to three years, providing consistent maintenance and support for your vehicles.',
        },
    ];

    return (
        <Fragment>
            <div className='overflow-hidden'>
                <div className='position-relative'>
                    <video autoPlay loop muted className='images__wrap-head'>
                        <source src='/video1.mp4' type='video/mp4' />
                        Your browser does not support the video tag.
                    </video>
                    <div className='wrapper__bg-head'></div>
                    <Navbar />

                    <section className='position-relative z-2' id='home'>
                        <div className='container'>
                            <div className='text-center'>
                                <h1 className='semi-bold font__size--90 text__90-1024 text__90-md text__90-sm text__90-xs  color__white text-uppercase mb-'>
                                    Welcome to GLOBAL HR
                                </h1>
                                <p className='medium roboto font__size--16 text__16-1024 color__white my-4 text-uppercase'>
                                    We are redefining excellence in the transportation industry
                                </p>
                            </div>
                            <div className='d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-center'>
                                <a
                                    href='#service'
                                    className='btn__mm-w-60 semi-bold rounded-0 font__size--14 text__14-1024 btn btn__orange shadow color__white text-uppercase'
                                >
                                    Discover service
                                </a>
                                <a
                                    href='#why'
                                    className='btn__mm-w-60  semi-bold font__size--14 text__14-1024 btn btn__outlined--white color__white no__opacity h__black rounded-0 shadow text-uppercase mt-3 mt-sm-0 ml-sm-4'
                                >
                                    why choose us
                                </a>
                            </div>
                        </div>
                    </section>
                </div>
                <section id='about' style={{ margin: 0, padding: 0 }}>
                    <About />
                    <About1 />
                </section>
                <section id='process' style={{ marginBottom: 10, padding: 0 }}>
                    <Step1 />
                </section>
                <section id='service' style={{ margin: 0, padding: 0 }}>
                    <Service service={service} />
                </section>

                <section id='why' style={{ margin: 0, padding: 0 }}>
                    <Contact />
                </section>

                {/* <section className='overflow-hidden'>
                    <div className='text-center mb-5'>
                        <p className='semi-bold font__size--14 text__14-1024 color__orange text-uppercase'>our work</p>
                        <h3 className='bold font__size--58 text__50-1024 text__50-sm text__50-xs text-uppercase'>Construction Projects</h3>
                    </div>
                    <SliderConstruction />
                </section> */}

                {/* <Blog blog={blog} title={'news & articles'} top={'latest blog'} /> */}

                {/* <Contact /> */}
                <Footer />
            </div>
        </Fragment>
    );
};

export default Index;
